import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ad-my-sports-banner',
  template: `
    <ng-container *ngIf="!hide">
      <div class="mt-11 ng-md:mt-14 bg-[rgb(60,60,60)]" [class.reset-margin-bottom]="url?.includes('runner')">
        <div #banner id="betrail_ht" class="ams-ad flex justify-center"></div>
        <button
          mat-icon-button
          class="!absolute right-5 top-16 scale-125 ng-md:top-20 ng-md:scale-150"
          (click)="closeBanner.emit()"
        >
          <mat-icon class="text-[gray]">close</mat-icon>
        </button>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .reset-margin-bottom {
        margin-bottom: -2.75rem;

        @media (min-width: 960px) {
          margin-bottom: -3.5rem;
        }
      }
    `,
  ],
})
export class AdMySportsBannerComponent implements AfterViewInit {
  @Input() hide = false;
  @Input() url = '';

  @Output() closeBanner = new EventEmitter<void>();

  @ViewChild('banner') banner: ElementRef;

  ngAfterViewInit() {
    if (this.banner) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://static.admysports.com/hb/site/betrail/hb_betrail.js';
      this.banner.nativeElement.appendChild(script);
    }
  }
}
