<result-form-header [result]="data.result"></result-form-header>
<div class="container">
  <div class="trail">
    <form class="form" (submit)="confirm()" [formGroup]="optionForm">
      <div>
        <div
          *ngIf="step === 1"
          class="question"
          innerHTML="{{ 'TELL_US_WHY_THIS_RESULT_IS_NOT_YOURS' | transloco }}"
        ></div>
        <div
          *ngIf="step === 2 && chosenOption === 'bib_transfer'"
          class="question"
          innerHTML="{{ 'DO_YOU_KNOW_THE_BIB_OWNER' | transloco }}"
        ></div>

        <div class="radios" *ngIf="step === 1">
          <mat-radio-group formControlName="option">
            <mat-radio-button value="bib_transfer">
              <ng-container *ngIf="isAdmin$ | async; else runnerFirstOption">
                Attribuer le résultat à une autre personne existant dans la base de données.
              </ng-container>
              <ng-template #runnerFirstOption> {{ 'SOMEONE_ELSE_RAN_WITH_MY_BIB' | transloco }} </ng-template>
            </mat-radio-button>
            <mat-radio-button value="homonymous">
              <ng-container *ngIf="isAdmin$ | async; else runnerSecondOption">
                Attribuer le résultat au meilleur homonyme (ou en créer un le cas échéant).
              </ng-container>
              <ng-template #runnerSecondOption> {{ 'THIS_RESULT_BELONGS_TO_HOMONYMOUS' | transloco }} </ng-template>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="step === 2">
          <div *ngIf="chosenOption === 'homonymous'">
            <div class="know" *ngIf="response === 'DONE'; else working">
              <mat-icon class="green">check</mat-icon> {{ 'DONE' | transloco }}
            </div>
          </div>
          <div *ngIf="chosenOption === 'bib_transfer'">
            <mat-radio-group formControlName="knowrunner" (change)="setKnowRunner($event)">
              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1em">
                <mat-radio-button value="oui" (change)="setKnowRunner($event)"
                  >{{ 'YES' | transloco }}</mat-radio-button
                >
                <mat-radio-button value="non" (change)="setKnowRunner($event)">{{ 'NO' | transloco }}</mat-radio-button>
              </div>
            </mat-radio-group>
            <div class="search" *ngIf="knowrunner === 'oui'">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'LASTNAME_FIRSTNAME' | transloco }}</mat-label>
                <input
                  class="searchBox"
                  fxFlex="100"
                  matInput
                  placeholder="NOM PRENOM"
                  formControlName="runnerName"
                  #searchElem
                  [matAutocomplete]="auto"
                  (keyup)="onSearchChange($any($event).target.value)"
                  required
                />
              </mat-form-field>
              <mat-autocomplete
                (optionSelected)="onSearchSelect($any($event).option.value)"
                #auto="matAutocomplete"
                connectedTo="#searchElem"
              >
                <div id="close">
                  <button mat-icon-button (click)="searchClose()"><mat-icon>close</mat-icon></button>
                </div>
                <ng-container *ngIf="searchAutocomplete | async; let auto">
                  <ng-container *ngIf="(auto.runners && auto.runners.length > 0) || auto.runner">
                    <ng-container *ngFor="let runner of auto.runners">
                      <mat-option *ngIf="+data.id !== +runner._id" [value]="runner">
                        <div class="search-item">
                          <div
                            *ngIf="runner?._source?.avatar; else defaultImg"
                            class="image-runner"
                            [style.background]="runner?._source?.avatar | sanitizeStyleUrl"
                          ></div>
                          <ng-template #defaultImg>
                            <div class="image-runner default">
                              <mat-icon>person</mat-icon>
                            </div>
                          </ng-template>
                          <div class="runner-info">
                            <span>{{ runner?._source.display_title }}</span>
                            <span class="runnerId"
                              >ID {{ runner?._source.ruid }} / Niveau : {{ runner?._source?.bt_level / 100 }}</span
                            >
                          </div>
                        </div>
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-autocomplete>
              <div
                *ngIf="runner === undefined; else torunner"
                class="description"
                innerHTML="{{ 'WRITE_FIRST_LETTERS_AND_CHOOSE' | transloco }}"
              ></div>
              <ng-template #torunner>
                <div class="description">{{ 'RESULT_WILL_BE_ATTRIBUTED_TO' | transloco }} : {{ runner.title }}.</div>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="step === 3">
          <div class="know" *ngIf="response === 'DONE'; else working">
            <mat-icon class="green">check</mat-icon> {{ 'DONE' | transloco }}
          </div>
        </div>
      </div>
      <div class="actions" fxLayout="row" fxLayoutGap="0.7em" fxLayoutAlign="center">
        <button class="button light-button" mat-button (click)="cancel($event)">{{ closeText | transloco }}</button>
        <button
          *ngIf="step < 3 || (step === 2 && chosenOption === 'bib_transfer')"
          type="submit"
          [disabled]="step === 2 && knowrunner === 'oui' && runner === undefined"
          class="button"
          mat-raised-button
        >
          {{ 'CONTINUE' | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>
<ng-template #working> {{ response | transloco }} </ng-template>
