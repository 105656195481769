import { Injectable } from '@angular/core';
import { IRegistration, IResultCreation, IRunner, Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { Path } from '@betrail-libs/shared/interfaces/path.model';
import { Result } from '@betrail-libs/shared/interfaces/result.model';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';
import { IEvent } from '../../shared/interfaces/event.model';
import { Race } from '../../shared/interfaces/race.model';
import {
  AddUserToOrganization,
  CreateNewResult,
  DeleteRaceById,
  DeleteResultById,
  EditAllowTrackingValue,
  EditResult,
  LoadTrailForAlias,
  SelectDistanceAlias,
  SelectDistanceFromQueryParamsOrAlias,
  loadPathsForRaceId,
  updatePathsForRaceId,
} from './trail-data.action';
import { Notification } from './trail-data.model';
import { TrailDataState } from './trail-data.state';

@Injectable({
  providedIn: 'root',
})
export class TrailDataService {
  constructor(private _store: Store) {}
  @Select(RouterState.url) url$!: Observable<string>;
  @Select(TrailDataState.selectedTrail) selectedTrail$: Observable<Trail>;
  @Select(TrailDataState.selectedEvent) selectedEvent$: Observable<IEvent>;
  @Select(TrailDataState.selectedDistance) selectedRace$: Observable<Race>;
  @Select(TrailDataState.selectedResult) selectedResult$!: Observable<Result>;
  @Select(TrailDataState.selectedEventRegistrations) selectedEventRegistrations$: Observable<IRegistration[]>;
  @Select(TrailDataState.selectedEventSignupRegistrations) selectedEventSignupRegistrations$: Observable<
    IRegistration[]
  >;
  @Select(TrailDataState.selectedRunner) runnerOne$!: Observable<Runner>;
  @Select(TrailDataState.selectedMatchupRunner) runnerMatchup$!: Observable<Runner>;
  @Select(TrailDataState.selectNotification) notifications$!: Observable<Notification[]>;
  @Select(TrailDataState.predictedEvents) predictedEvents$!: Observable<IEvent[]>;

  getRaceById(raceId: any): any {
    return this._store.select(state => state.trailData?.races?.[raceId]);
  }
  // RACES / EVENTS / TRAILS

  @Dispatch()
  selectDistanceAlias = (distanceAlias?) => new SelectDistanceAlias(distanceAlias);

  getRaceByIdSnap(raceId: any): any {
    return this._store.selectSnapshot(state => state.trailData?.races?.[raceId]);
  }

  getFirstDistanceEnabledAliasSnapshot(): any {
    return this._store.selectSnapshot(state =>
      TrailDataState.selectDefaultDistanceAliasForRegistration(state.trailData),
    );
  }

  geRaceByAlias(distance: any): any {
    return this._store.select(
      state =>
        state.trailData?.raceAlias?.[
          state.trailData.selectedTrailAlias + state.trailData.selectedEventAlias + distance
        ],
    );
  }

  selectDistanceFromAliasOrQueryParams(select: boolean, alias?: string) {
    return this._store.dispatch(new SelectDistanceFromQueryParamsOrAlias(select, alias));
  }

  deleteRaceData(raceId: number | string) {
    return this._store.dispatch(new DeleteRaceById(raceId));
  }

  deleteRaceResult(resultId: number, runnerId?: number, raceId?: number) {
    return this._store.dispatch(new DeleteResultById(resultId, runnerId, raceId));
  }

  createRaceResult(data: IResultCreation) {
    return this._store.dispatch(new CreateNewResult(data));
  }

  editResultData(result: Result, moveToRaid?: string | number, newRaceTitle?: string) {
    return this._store.dispatch(new EditResult(result, moveToRaid, newRaceTitle));
  }

  getSelectedEventSnapshot(): IEvent {
    return this._store.selectSnapshot(TrailDataState.selectedEvent);
  }

  getSelectedTrail() {
    return this._store.select(TrailDataState.selectedTrail);
  }

  getSelectedTrailSnapshot() {
    return this._store.selectSnapshot(TrailDataState.selectedTrail);
  }

  getRaceSignupInfoSnap(distanceAlias): any {
    let race = Object.values(this._store.selectSnapshot(TrailDataState.selectRaces)).find(
      race => race.alias === distanceAlias || race.id === distanceAlias,
    );
    return race && race.signupData;
  }

  loadTrailByAlias = (trailAlias: string, storeCache = false) => {
    return this._store
      .dispatch(new LoadTrailForAlias(trailAlias, storeCache))
      .pipe(switchMap(() => this._store.select(state => TrailDataState.trailByAlias(state.trailData, trailAlias))));
  };

  @Dispatch()
  dismissNotification(action: any) {
    return action;
  }

  loadPathsForRaceId(raceId: string | number) {
    this._store.dispatch(new loadPathsForRaceId(raceId));
  }

  updatePathsForRaceId(raceId: string | number, paths: Path[]) {
    return this._store.dispatch(new updatePathsForRaceId(raceId, paths));
  }

  // USERS / RUNNERS

  editAllowTrackingValue(ruid: number, value: boolean) {
    this._store.dispatch(new EditAllowTrackingValue(ruid, value));
  }

  getCurrentUserRunnerSnapshot(): IRunner {
    return this._store.selectSnapshot(state => TrailDataState.currentUserRunner(state.trailData));
  }

  getCurrentUserRunner(): Observable<IRunner> {
    return this._store.select(state => TrailDataState.currentUserRunner(state.trailData));
  }

  getCurrentRunnerLastAccess(): Observable<number | undefined> {
    return this.runnerOne$.pipe(map(r => r?.user?.access));
  }

  linkUserWithOrga(orgaId: number, userId: number) {
    this._store.dispatch(new AddUserToOrganization(orgaId, userId));
  }
}
