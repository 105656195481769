import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { AuthStateService } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService } from '@betrail-libs/trail-data-state';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'bux-search-runner-trail',
  templateUrl: './search-runner-trail.component.html',
  styleUrls: ['./search-runner-trail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRunnerTrailComponent implements OnInit {
  @Input() placeholders = [];
  @Input() discreet: boolean = false;
  @Input() link: boolean = true;
  @Input() searchRunner: boolean = false;
  @Input() runnerTwoName: string = '';
  @Input() isFromMatchup = false;
  @Input() forceFullWidth = false;
  @Output('optionSelected') optionSelectedEvent = new EventEmitter<any>();

  @ViewChild('searchElem', { read: MatAutocompleteTrigger, static: true }) searchElem: MatAutocompleteTrigger;
  @ViewChild('searchElem', { static: true }) searchNativeElem: ElementRef<HTMLInputElement>;

  searchStatus: boolean = false;
  searchValue = new Subject<any>();
  searchAutocomplete: Observable<{ runner?: any; runners?: any[]; events?: any[] }> = this.searchValue.pipe(
    debounceTime(80),
    switchMap(value => (value ? this.eventService.searchRunners(value) : of({}))),
  );

  currentSearchValue: any;

  defaultCountry: string;
  justSelected = false;
  isOpen = false;

  isAdmin$ = this.authState.hasRole(DrupalRole.Administrator);
  isEncoder$ = this.authState.hasRole(DrupalRole.Encoder);

  constructor(
    public translation: TranslocoService,
    public router: Router,
    private eventService: EventService,
    private authState: AuthStateService,
  ) {}

  ngOnInit() {
    this.searchElem.position = 'below';
  }

  removeEventsToHide(events) {
    let ev = events.filter(e => e.hide !== 1);
    return ev;
  }

  onSearchChange(value) {
    if (value != this.currentSearchValue) {
      this.searchValue.next(value.trim());
      this.currentSearchValue = value.trim();
    }
    if (value != '' && this.justSelected == false) {
      this.searchElem.openPanel();
      this.isOpen = true;
    } else {
      this.searchElem.closePanel();
      this.isOpen = false;
      if (this.justSelected == true) {
        this.justSelected = false;
      }
    }
  }

  onSearchSelect(value) {
    if (this.link) {
      this.searchStatus = false;
      this.justSelected = true;
      if (value._source && !value?._source?.alias.includes('/')) {
        this.router.navigate(['/', 'runner', value._source.alias]);
      } else if (value._source && value._source.ruid) {
        this.router.navigate(['/', 'runner', value._source.ruid]);
      } else if (value.ruid) {
        this.router.navigate(['/', 'runner', value.alias]);
      } else {
        this.router.navigate(['/', 'race', value.trail_alias, value.event_alias]);
      }
    } else {
      this.selectRunner(value);
    }
    this.searchClose();
    this.resetInput();
  }

  toggleSearch() {
    this.searchNativeElem.nativeElement.focus();
    this.searchStatus = !this.searchStatus;
    if (this.searchStatus == true) {
      this.searchNativeElem.nativeElement.setSelectionRange(0, this.searchNativeElem.nativeElement.value.length);
      this.searchElem.openPanel();
    }
  }

  resetInput() {
    this.searchNativeElem.nativeElement.value = '';
    this.currentSearchValue = '';
    this.searchValue.next('');
  }

  searchClose() {
    this.searchElem.closePanel();
    this.searchStatus = false;
  }

  setIsOpenParam(val) {
    this.isOpen = val;
  }

  selectRunner(runner) {
    this.optionSelectedEvent.emit(runner);
  }
}
