<mat-toolbar class="toolbar" ngClass.lt-md="small" [class.show]="showBar" [class.hide]="!showBar">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between">
    <div class="logoAndMenu" ngClass.lt-md="small" fxLayout="row" fxLayoutAlign="center center">
      <!-- * LEFT MENU TRIGGER & BETRAIL LOGO * -->
      <div id="menuToggle" (click)="toggleMenu()" fxFlex="30" fxFlex.gt-sm="30" ngClass.gt-md="large">
        <mat-icon>menu</mat-icon>
      </div>
      <span [routerLink]="['']" class="logo" title="Betrail"> </span>
    </div>
    <div class="barContent" fxFlex fxLayout="row" fxLayoutAlign="end center">
      <!-- * MAIN SEARCH BAR * -->
      <button
        mat-fab
        class="search-button"
        [class.searchToggle]="!(auth$ | async)"
        [class.searchToggleLogged]="auth$ | async"
        (click)="toggleTopSearch()"
        ngClass.gt-sm="large"
        ngClass.lt-md="small"
      >
        <mat-icon>search</mat-icon>
      </button>
      <div class="bar-content-search" [class.searchBar]="!(auth$ | async)" [class.searchBarLogged]="auth$ | async">
        <bux-search-runner-trail class="top-search" [discreet]="true" [placeholders]="['FIND_RUNNER_OR_RACE']">
        </bux-search-runner-trail>
      </div>

      <!-- * DISCOVER PREMIUM LINK * -->
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100">
        <a
          ngClass.gt-sm="large"
          [routerLink]="['/', 'premium', 'discover']"
          mat-button
          class="premium"
          [class.premiumBtn]="!(auth$ | async)"
        >
          <ng-container *ngIf="runner?.is_premium === 1; else becomePremium"
            >{{ 'MENU_PREMIUM' | transloco }}</ng-container
          >
          <ng-template #becomePremium>{{ 'BECOME_PREMIUM' | transloco }}</ng-template>
        </a>

        <ng-container *ngIf="auth$ | async; else noLogged; let auth">
          <!-- * SHOPPING CART * -->
          <button
            *ngIf="hasCartItem$ | async"
            id="cart"
            mat-icon-button
            [routerLink]="['/cart']"
            fxFlex="none"
            class="shoppingCart-button"
          >
            <mat-icon
              *ngIf="(nbrRegItem$ | async) > 0; else defaultShoppingIcon"
              [matBadge]="nbrRegItem$ | async"
              matBadgePosition="above after"
              >shopping_cart</mat-icon
            >
          </button>
          <ng-template #defaultShoppingIcon>
            <mat-icon>shopping_cart</mat-icon>
          </ng-template>

          <!-- * NOTIFICATIONS * -->
          <ng-container>
            <button
              class="notifications-button"
              [matBadge]="newNotificationsNumber"
              [matBadgeHidden]="newNotificationsNumber === 0"
              mat-mini-fab
              color="accent"
              aria-label="Notifications"
              [matMenuTriggerFor]="userNotificationsMenu"
              #t="matMenuTrigger"
            >
              <mat-icon>notifications</mat-icon>
            </button>
            <mat-menu
              class="notifications-menu bt-shadowed bt-rounded"
              #userNotificationsMenu="matMenu"
              yPosition="below"
              [overlapTrigger]="false"
            >
              <ng-container>
                <user-notifications
                  [open]="t.menuOpen"
                  (numberUpdated)="setNewNotificationsNumber($event)"
                ></user-notifications
              ></ng-container>
            </mat-menu>
          </ng-container>
          <ng-template #defaultNotificationsIcon>
            <button
              class="notifications-button"
              mat-mini-fab
              color="accent"
              aria-label="Notifications"
              [matMenuTriggerFor]="userNotificationsMenu"
            >
              <mat-icon>notifications</mat-icon>
            </button>
          </ng-template>

          <!-- * LOGGED USER DROP MENU * -->
          <div id="userMenu">
            <button mat-button [matMenuTriggerFor]="userProfileMenu">
              <div class="user-img-container">
                <ng-container *ngIf="runner?.photo?.little_square_image; else: falseImage">
                  <img class="circle" [src]="runner?.photo?.little_square_image | sanitizeUrl" />
                </ng-container>
                <ng-template #falseImage>
                  <div class="falseImage">
                    <span>{{ user?.userDrupal?.firstname?.charAt(0) || runner?.firstname?.charAt(0) }}</span>
                  </div>
                </ng-template>
                <div class="premium-icon" *isPremium>P</div>
              </div>
              <span [fxHide]="true" [fxHide.gt-md]="false">{{ user.userDrupal?.firstname || runner?.firstname }}</span>
              <mat-icon ngClass.lt-md="userExpandIcon">expand_more</mat-icon>
            </button>
            <mat-menu #userProfileMenu="matMenu" yPosition="below" [overlapTrigger]="false">
              <div class="user_name_header">
                {{ 'USER_HELLO' | transloco }}<ng-container
                  *ngIf="
                    user?.userDrupal
                  "
                  >, {{ user.userDrupal.firstname }}</ng-container
                >
                <ng-container
                  *ngIf="
                  user.userDrupal?.lastname
                  "
                >
                  {{ user.userDrupal?.lastname }}</ng-container
                >
                !
              </div>
              <ng-container *ngIf="user?.runner">
                <div class="menuSection">{{ 'MENU_RUNNER_SPACE' | transloco }}</div>
                <a class="no-underline" mat-menu-item [routerLink]="['runner', 'my-page']">
                  <mat-icon>insert_chart</mat-icon>{{ 'MENU_MY_PAGE' | transloco }}
                </a>
                <a class="no-underline" mat-menu-item [routerLink]="['runner', 'my-page', 'news-feed']">
                  <mat-icon>art_track</mat-icon>{{ 'MENU_MY_NEWS_FEED' | transloco }}
                </a>
                <a class="no-underline" mat-menu-item [routerLink]="['runner', 'my-page', 'agenda']">
                  <mat-icon>event</mat-icon>{{ 'MENU_MY_AGENDA' | transloco }}
                </a>
                <a class="no-underline" mat-menu-item [routerLink]="['runner', 'my-page', 'results']">
                  <mat-icon>assignment</mat-icon>{{ 'MENU_MY_RESULTS' | transloco }}
                </a>
                <a class="no-underline" mat-menu-item [routerLink]="['runner', 'my-page', 'stats']">
                  <mat-icon>pie_chart</mat-icon>{{ 'MENU_MY_STATS' | transloco }}
                </a>
              </ng-container>

              <ng-container *ngIf="user?.organizations?.length > 0">
                <div class="menuSection">{{ 'MENU_ORGA_SPACE' | transloco }}</div>
                <a *isOrga mat-menu-item [routerLink]="['organizer', 'my-organization']">
                  <mat-icon>supervisor_account</mat-icon>{{ 'MY_ORGANIZER_SPACE' | transloco }}
                </a>
                <!-- * TO KEEP * Djo will probably bring it back when trail dashboards become more useful -->
                <!-- <ng-container *ngFor="let orga of user?.organizations">
                  <ng-container *ngIf="orga.trails; let userOrgaTrails">
                    <ng-container *ngIf="userOrgaTrails?.length <= 3; else defaultOrgaSpaceButton">
                      <a
                        *ngFor="let trail of userOrgaTrails"
                        mat-menu-item
                        [routerLink]="['organizer', trail.alias, 'dashboard']"
                      >
                        <mat-icon>supervisor_account</mat-icon>{{ trail.title }}
                      </a>
                    </ng-container>
                    <ng-template #defaultOrgaSpaceButton>
                      <button *isOrga mat-menu-item [routerLink]="['organizer', 'my-organization']">
                        <mat-icon>supervisor_account</mat-icon>{{ 'MENU_MY_ORGANIZER_SPACE' | transloco }}
                      </button>
                    </ng-template>
                  </ng-container>
                </ng-container> -->
              </ng-container>

              <div class="menuSection" *isEncoder>{{ 'MENU_ADMINISTRATION' | transloco }}</div>
              <a *isAdmin mat-menu-item [routerLink]="['admin']">
                <mat-icon>lock</mat-icon>{{ 'MENU_ADMIN' | transloco }}
              </a>
              <a *isEncoder mat-menu-item href="https://beta.betrail.run/encodage" target="_blank">
                <mat-icon svgIcon="results"></mat-icon>{{ 'MENU_ENCODING' | transloco }}
              </a>
              <a *isEncoder mat-menu-item [routerLink]="['calendar', 'additions']">
                <mat-icon>edit_calendar</mat-icon>{{ 'MENU_CALENDAR_ADDITIONS' | transloco }}
              </a>
              <a *isEncoder mat-menu-item [routerLink]="['encoder', 'game']">
                <mat-icon>videogame_asset</mat-icon>{{ 'GAMIFICATION' | transloco }}
              </a>
              <a *isAdmin mat-menu-item (click)="masquerade.openInputMasquerade()">
                <mat-icon>people</mat-icon>Masquerade
              </a>

              <div class="menuSection">{{ 'MENU_ACCOUNT' | transloco }}</div>
              <a mat-menu-item [routerLink]="['runner', 'my-page', 'edit']">
                <mat-icon>settings</mat-icon>{{ 'MENU_ACCOUNT_SETTINGS' | transloco }}
              </a>
              <a mat-menu-item [routerLink]="['cart']" *ngIf="hasCartItem$ | async">
                <mat-icon>shopping_cart</mat-icon>{{ 'MENU_MY_CART' | transloco }}
              </a>
              <a mat-menu-item [routerLink]="['cart', 'history']" *ngIf="user.allOrders?.length > 0">
                <mat-icon>assignment</mat-icon>{{ 'MENU_MY_ORDERS' | transloco }}
              </a>
              <a mat-menu-item [routerLink]="['cart', 'my-registrations']" *ngIf="user.allOrders?.length > 0">
                <mat-icon>assignment_ind</mat-icon>{{ 'MENU_MY_REGISTRATIONS' | transloco }}
              </a>
              <a mat-menu-item (click)="logout.emit()">
                <mat-icon>arrow_forward</mat-icon>{{ 'MENU_LOGOUT' | transloco }}
              </a>
            </mat-menu>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #loadingUser>
      <div class="loading-user" fxLayoutAlign="end center" fxFlex="100">
        <em>{{ 'LOADING_USER' | transloco }}</em>
      </div>
    </ng-template>
    <ng-template #noLogged>
      <div *ngIf="!(isLoadingUser$ | async); else loadingUser" class="flex items-center">
        <!-- * NOT LOGGED BUTTONS * -->
        <div class="menu" id="userMenu">
          <a mat-button class="connectionBtn" ngClass.gt-sm="large" (click)="loginAction()"
            >{{ 'MENU_LOGIN' | transloco }}</a
          >
          <a class="highlighted-button createAccountBtn" ngClass.gt-sm="large" mat-button (click)="createAccount.emit()"
            >{{ 'CREATE_ACCOUNT' | transloco }}</a
          >
          <button mat-button [matMenuTriggerFor]="languageMenu" class="langButton">
            <country-flag [country]="translation?.getActiveLang().toUpperCase()"></country-flag>
            {{ translation.getActiveLang() }}<mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #languageMenu="matMenu" yPosition="below" [overlapTrigger]="false">
            <button
              fxLayout="row"
              [class.langSelected]="translation.getActiveLang() === 'fr'"
              mat-menu-item
              (click)="selectLang('fr')"
            >
              <country-flag country="FR"></country-flag> <span>{{ 'LANG_FR' | transloco }}</span>
            </button>
            <button
              fxLayout="row"
              [class.langSelected]="translation.getActiveLang() === 'nl'"
              mat-menu-item
              (click)="selectLang('nl')"
            >
              <country-flag country="NL"></country-flag> <span>{{ 'LANG_NL' | transloco }}</span>
            </button>
            <button
              fxLayout="row"
              [class.langSelected]="translation.getActiveLang() === 'en' || !translation.getActiveLang()"
              mat-menu-item
              (click)="selectLang('en')"
            >
              <country-flag country="GB"></country-flag> <span>{{ 'LANG_EN' | transloco }}</span>
            </button>
          </mat-menu>
        </div>

        <!-- * NOT LOGGED DROP MENU * -->
        <button id="notLoggedMenu" mat-button [matMenuTriggerFor]="userSmallMenu" class="smallMenu">
          <mat-icon class="personIcon">person</mat-icon>
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #userSmallMenu="matMenu" yPosition="below" class="!overflow-hidden" [overlapTrigger]="false">
          <button mat-menu-item class="loginIcon connectionBtn menuItem" (click)="login.emit()">
            <mat-icon>account_circle</mat-icon>{{ 'MENU_LOGIN' | transloco }}
          </button>
          <button mat-menu-item class="loginIcon createAccountBtn menuItem" (click)="createAccount.emit()">
            <mat-icon>person_add</mat-icon>{{ 'CREATE_ACCOUNT' | transloco }}
          </button>
          <button mat-menu-item class="loginIcon premiumBtn menuItem" [routerLink]="['/', 'premium', 'discover']">
            <mat-icon>directions_run</mat-icon>{{ 'BECOME_PREMIUM' | transloco }}
          </button>
          <div class="menuSection">{{ 'MENU_LANGUAGES' | transloco }}</div>
          <button
            fxLayout="row"
            [class.langSelected]="translation.getActiveLang() === 'fr'"
            mat-menu-item
            (click)="selectLang('fr')"
          >
            <country-flag class="flag" country="FR"></country-flag> <span>{{ 'LANG_FR' | transloco }}</span>
          </button>
          <button
            fxLayout="row"
            [class.langSelected]="translation.getActiveLang() === 'nl'"
            mat-menu-item
            (click)="selectLang('nl')"
          >
            <country-flag class="flag" country="NL"></country-flag> <span>{{ 'LANG_NL' | transloco }}</span>
          </button>
          <button
            fxLayout="row"
            [class.langSelected]="translation.getActiveLang() === 'en' || !translation.getActiveLang()"
            mat-menu-item
            (click)="selectLang('en')"
          >
            <country-flag class="flag" country="GB"></country-flag> <span>{{ 'LANG_EN' | transloco }}</span>
          </button>
        </mat-menu>
      </div>
    </ng-template>
  </mat-toolbar-row>

  <!-- * SUB SEARCH BAR FOR SMALLER SCREENS -->
  <mat-toolbar-row
    class="top-search-container"
    fxLayout="row"
    *ngIf="showTopSearch"
    [class.searchToggle]="!(auth$ | async)"
    [class.searchToggleLogged]="auth$ | async"
  >
    <bux-search-runner-trail
      style="width: 100%; max-height: inherit"
      [forceFullWidth]="true"
      [placeholders]="['FIND_RUNNER_OR_RACE']"
    >
    </bux-search-runner-trail>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="isLoading | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-toolbar-row>
</mat-toolbar>
