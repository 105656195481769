import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { EAdminSettingType, IAdminSettings } from '@betrail-libs/shared/interfaces/interfaces';
import { EPopUpStatus, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  private readonly apiUri = this.config.nodejsApi + '/admin/';

  constructor(@Inject('appsConfig') private config: any, private http: HttpClient) {}

  // * ------------------- ADMIN ACTIONS ------------------- *

  getMailingList = () =>
    this.http.get<{ uid: number; mail: string; language: string; defaultCountry?: string }[]>(this.apiUri + 'mailing');

  updateEliteLevels = () => this.http.get<{ time: number }>(this.apiUri + 'elites/update');

  // * ------------------- DEV UTILS ------------------- *

  clearNecessaryData = (type: 'spaces' | 'particles' | 'pending') =>
    this.http.put<{ nbOfChanges: number }>(`${this.apiUri}dev/clear/data`, { type });

  clearUrlsFromFbTracking = (type: 'trail' | 'event' | 'race') =>
    this.http.put<{ nbOfChanges: number }>(`${this.apiUri}dev/clear/links`, { type });

  deleteUnusedFromDatabase = (type: 'runners') =>
    this.http.delete<{ deleted: number }>(`${this.apiUri}dev/delete/${type}`);

  // * ------------------- POP-UPS ------------------- *

  migrateData = () => this.http.get(this.apiUri + 'popup/migrate');

  getAllPopUps = () => this.http.get<IPopUpData[]>(this.apiUri + 'popup/all');

  getActivePopups = () => this.http.get<IPopUpData[] | null>(this.apiUri + 'popup/active');

  createPopup = (newPopup: Partial<IPopUpData>) => this.http.post<IPopUpData>(this.apiUri + 'popup/create', newPopup);

  createPopupLog = (data: { userId: number; popupId: number; linkClicked: boolean; timeOpen: number }) =>
    this.http.post<IPopUpData>(this.apiUri + 'pl/create', data);

  editPopup = (editedPopup: IPopUpData) => this.http.put<IPopUpData>(this.apiUri + 'popup/edit', editedPopup);

  addPopupView = (popupId: number) => this.http.put<IPopUpData>(this.apiUri + 'popup/view', { popupId });

  changeStatusOfPopup = (popupId: number, newStatus: EPopUpStatus, oldStatus: EPopUpStatus) =>
    this.http.put<IPopUpData>(this.apiUri + 'popup/status', { popupId, newStatus, oldStatus });

  // * ------------------- SETTINGS ------------------- *

  createAdminSetting = (setting: IAdminSettings) =>
    this.http.post<IAdminSettings>(this.apiUri + 'settings/create', setting);

  getAllAdminSettings = () => this.http.get<{ [type: string]: IAdminSettings }>(this.apiUri + 'settings/all');

  saveAdminSetting = (setting: IAdminSettings) =>
    this.http.put<IAdminSettings>(this.apiUri + 'settings/update', setting);
}
