import { IClaimedElement, IResultCreation } from '@betrail-libs/shared/interfaces/interfaces';
import { OtherResult } from '@betrail-libs/shared/interfaces/otherResult.model';
import { Path } from '@betrail-libs/shared/interfaces/path.model';
import { Result } from '@betrail-libs/shared/interfaces/result.model';
import { Race } from '../../shared/interfaces/race.model';

export class SelectDistanceFromQueryParamsOrAlias {
  static readonly type = '[TrailData] Select Distance From Query Params Or Alias';
  constructor(public select: boolean, public raceAlias?: string) {}
}
export class SelectTrailAlias {
  static readonly type = '[TrailData] Select trail Alias';
  constructor(public trailAlias: string) {}
}
export class SelectOrganizationAlias {
  static readonly type = '[TrailData] Select organization Alias';
  constructor(public organizationAlias: string) {}
}
export class SelectEventAlias {
  static readonly type = '[TrailData] Select event Alias';
  constructor(public eventAlias: string) {}
}
export class SelectDistanceAlias {
  static readonly type = '[TrailData] Select distance Alias';
  constructor(public distanceAlias: string) {}
}
export class SelectRunnerAlias {
  static readonly type = '[TrailData] Select runner Alias';
  constructor(public runnerAlias: string) {}
}
export class SelectResultId {
  static readonly type = '[TrailData] Select result Id';
  constructor(public resultId: number) {}
}
export class LoadTrailForAlias {
  static readonly type = '[TrailData] load trail for Alias';
  constructor(public trailAlias: string, public storeCache = false) {}
}
export class LoadOrganizationForAlias {
  static readonly type = '[TrailData] load organization for Alias';
  constructor(public organizationAlias: string) {}
}
export class AddUserToOrganization {
  static readonly type = '[TrailData] Add user to organization';
  constructor(public organizationId: number, public userId: number) {}
}
export class LoadRunnerForAlias {
  static readonly type = '[TrailData] load runner for Alias';
  constructor(public runnerAlias: string) {}
}
export class LoadRunnerById {
  static readonly type = '[TrailData] load runner by Id';
  constructor(public runnerId: string) {}
}
export class LoadLevelByCategoriesForRunnerId {
  static readonly type = '[TrailData] load level by categories for runner Id';
  constructor(public runnerId: string) {}
}
export class UpdateRunnerById {
  static readonly type = '[TrailData] update runner by Id';
  constructor(public id: string, public runnerData) {}
}
export class UpdateRunnerScores {
  static readonly type = '[TrailData] update score for runner';
  constructor(public runnerId: string) {}
}
export class EditAllowTrackingValue {
  static readonly type = '[TrailData] edit allow tracking value';
  constructor(public runnerId: number, public value: boolean) {}
}
export class loadResultsForRaceId {
  static readonly type = '[TrailData] load results for race Id';
  constructor(public raceId: number | string, public force = false) {}
}
export class loadPathsForRaceId {
  static readonly type = '[TrailData] load paths for race Id';
  constructor(public raceId: number | string) {}
}

export class DeleteRaceById {
  static readonly type = '[TrailData] delete race by id';
  constructor(public raceId: number | string) {}
}

export class updatePathsForRaceId {
  static readonly type = '[TrailData] update paths for race Id';
  constructor(public raceId: number | string, public paths: Path[]) {}
}
export class loadPathForRaceId {
  static readonly type = '[TrailData] load path for race Id';
  constructor(public raceId: number | string) {}
}
export class loadResultsForRunnerId {
  static readonly type = '[TrailData] load results for runner Id';
  constructor(public runnerId: number) {}
}

export class DeleteResultById {
  static readonly type = '[TrailData] delete result by id';
  constructor(public resultId: number, public runnerId?: number, public raceId?: number) {}
}

export class CreateNewResult {
  static readonly type = '[TrailData] create new result';
  constructor(public data: IResultCreation) {}
}

export class EditResult {
  static readonly type = '[TrailData] edit result';
  constructor(public result: Result, public raid?: string | number, public title?: string) {}
}

export class loadOtherResultsForRunnerId {
  static readonly type = '[TrailData] load other results for user Id';
  constructor(public runnerId: number) {}
}
export class editResultTag {
  static readonly type = '[TrailData] edit result tag';
  constructor(public result: Result | OtherResult, public isOtherResult: boolean) {}
}
export class loadOtherResultsForRaceId {
  static readonly type = '[TrailData] load other results for race Id';
  constructor(public raceId: number) {}
}
export class loadRegistrationsForRunnerId {
  static readonly type = '[TrailData] load registrations for runner Id';
  constructor(public runnerId: number) {}
}
export class loadRegistrationsForRaceId {
  static readonly type = '[TrailData] load registrations for race Id';
  constructor(public raceId: number, public offset: number) {}
}

export class loadRegistrationsForEventId {
  static readonly type = '[TrailData] load registrations for event Id';
  constructor(public eventId: number, public offset: number) {}
}
export class loadSignupRegistrationsForRaceId {
  static readonly type = '[TrailData] load signup registrations for race Id';
  constructor(public trailId: number, public eventId: number, public raceId: number) {}
}
export class loadSignupRegistrationsForEventId {
  static readonly type = '[TrailData] load signup registrations for event Id';
  constructor(public trailId: number, public eventId: number) {}
}
export class loadSignupRegistrationsForEventIdWithRunners {
  static readonly type = '[TrailData] load signup registrations for event Id with runners';
  constructor(public trailId: number, public eventId: number) {}
}

export class LoadSignupRegistrationWithRunner {
  static readonly type = '[TrailData] load signup registration by Id with runner';
  constructor(public regId: string) {}
}
export class LoadSignupRegistrationForSelectedEvent {
  static readonly type = '[TrailData] load signup registrations for current selected event';
}

export class loadResultsPositionsForRunnerId {
  static readonly type = '[TrailData] load results positions for runner Id';
  constructor(public runnerId: number) {}
}
export class loadScoresPositionsForRunnerId {
  static readonly type = '[TrailData] load scopres positions for runner Id';
  constructor(public runnerId: number) {}
}
/*
export class loadLevelPositionForRunnerId {
  static readonly type = '[TrailData] load level position for runner Id';
  constructor(public runnerId: number, public runnerNationality: any = 'all') {}
}
*/
export class loadEvents {
  static readonly type = '[TrailData] load events';
  constructor(public afterDate: number, public beforeDate: number) {}
}
export class loadCloseFutureEvents {
  static readonly type = '[TrailData] load close future events';
  constructor(public afterDate: number, public beforeDate: number) {}
}
export class loadFutureEvents {
  static readonly type = '[TrailData] load future events';
  constructor(public afterDate: number, public beforeDate: number) {}
}
export class loadEventsPacket {
  static readonly type = '[TrailData] load events packets';
  constructor(public afterDate: number, public clear = false, public country = 'all', public forAdditionPage = false) {}
}
export class MarkClaimedEvents {
  static readonly type = '[TrailData] mark claimed events';
  constructor(public claims: IClaimedElement[]) {}
}
export class ClaimEvent {
  static readonly type = '[TrailData] claim event';
  constructor(public claim: IClaimedElement) {}
}
export class loadUserRunnerRegistrations {
  static readonly type = '[TrailData] load registrations of current user';
}
export class AddUserRunnerRegistration {
  static readonly type = '[TrailData] add registration for current user runner';
  constructor(public race: Race) {}
}
export class RemoveUserRunnerRegistration {
  static readonly type = '[TrailData] remove registration for current user runner';
  constructor(public race: Race) {}
}
export class selectEvents {
  static readonly type = '[TrailData] select events by Date';
  constructor(public afterDate: number, public beforeDate) {}
}
export class selectFutureEvents {
  static readonly type = '[TrailData] select events by Date';
  constructor(public afterDate: number, public beforeDate) {}
}
export class selectRaceResults {
  static readonly type = '[TrailData] select results of a race';
  constructor(public raceId: number) {}
}
export class SetSelectedType {
  static readonly type = '[TrailData] set selected route type';
  constructor(public type: string) {}
}

export class AddSignupData {
  static readonly type = '[TrailData] add signupData to store elements';
  constructor(public trailSignupData) {}
}

export class SetCurrentUserRunnerId {
  static readonly type = '[TrailData] set current user runner id';
  constructor(public id, public runner?: any) {}
}

export class SetSelectedDistanceSubPage {
  static readonly type = '[TrailData] set selected distance sub page';
  constructor(public subPageTitle) {}
}

export class loadMatchupRunnerById {
  static readonly type = '[TrailData] load Matchup runner for runner Id';
  constructor(public runnerId: number) {}
}

// Sponsors
export class LoadAllSponsors {
  static readonly type = '[TrailData] Load all sponsors';
}

export class LoadUserSponsors {
  static readonly type = '[TrailData] Load sponsors of the user';
  constructor(public userId: string | number) {}
}

export class LoadRunnersOfSponsor {
  static readonly type = '[TrailData] Load runners of the sponsor';
  constructor(public spid: string | number) {}
}

export class CreateNewSponsor {
  static readonly type = '[TrailData] Create new sponsor';
  constructor(public sponsor: any, public role: string, public user: any) {}
}

export class RemoveSponsorFromUser {
  static readonly type = '[TrailData] Remove sponsor from user';
  constructor(public uid: string | number, public spid: string | number) {}
}

export class UpdateSponsorData {
  static readonly type = '[TrailData] Update sponsor data';
  constructor(public sponsor: any) {}
}

export class DeleteSponsor {
  static readonly type = '[TrailData] Delete sponsor';
  constructor(public sponsorId: string | number) {}
}
