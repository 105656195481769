import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetUrlLang, LoadBundle, UseLang } from './i18nState/i18n.action';
import { AdminGuard } from './admin.guard';
import { HasRoleGuard } from './hasRole.guard';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';

@Injectable()
export class LangAliasResolver {
  constructor(private store: Store, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const langs = ['fr', 'nl', 'en'];
    if (route.params.lang && route.params.lang !== 'undefined') {
      if (langs.indexOf(route.params.lang) >= 0) {
        this.store.dispatch(new SetUrlLang(route.params.lang));
        this.store.dispatch(new LoadBundle(route.params.lang));
        this.store.dispatch(new UseLang(route.params.lang));
      } else {
        this.router.navigate(['/home']);
      }
    }
  }
}

const baseRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home-page/home-page.module').then(mod => mod.HomePageModule),
    data: { page: 'home' },
  },
  { path: 'premium', loadChildren: () => import('./premium/premium.module').then(mod => mod.PremiumModule) },
  {
    path: 'page',
    loadChildren: () => import('./single-pages/single-pages.module').then(mod => mod.SinglePagesModule),
  },
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then(mod => mod.BlogModule) },
  {
    path: 'calendrier-trail',
    loadChildren: () => import('./calendar/calendar.module').then(mod => mod.CalendarModule),
  },
  {
    path: 'trail-kalender',
    loadChildren: () => import('./calendar/calendar.module').then(mod => mod.CalendarModule),
  },
  {
    path: 'trailrunning-calendar',
    loadChildren: () => import('./calendar/calendar.module').then(mod => mod.CalendarModule),
  },
  { path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then(mod => mod.CalendarModule) },
  { path: 'runner', loadChildren: () => import('./runner/runner.module').then(mod => mod.RunnerModule) },
  { path: 'trail', redirectTo: 'race' },
  { path: 'organizer', loadChildren: () => import('./organizer/organizer.module').then(mod => mod.OrganizerModule) },
  { path: 'race', loadChildren: () => import('./race/race.module').then(mod => mod.RaceModule) },
  { path: 'trail', redirectTo: 'race', pathMatch: 'prefix' },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'encoder',
    loadChildren: () => import('./encoder/encoder.module').then(mod => mod.EncoderModule),
    canActivate: [HasRoleGuard],
    data: { roles: [DrupalRole.Encoder, DrupalRole.Administrator] },
  },
  { path: 'reg', loadChildren: () => import('./signup/signup.module').then(mod => mod.SignupModule) },
  {
    path: 'my-account',
    loadChildren: () => import('./user-account/user-account.module').then(mod => mod.UserAccountModule),
  },
  {
    path: 'encodage',
    loadChildren: () => import('./lazyImport.module').then(mod => mod.LazyWrapperModule),
    canActivate: [HasRoleGuard],
    data: { roles: [DrupalRole.Encoder, DrupalRole.Administrator] },
  },
  {
    path: 'rankings',
    loadChildren: () => import('./rankings-page/rankings-page.module').then(mod => mod.RankingsPageModule),
  },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(mod => mod.SignupModule) },
  { path: 'cart', loadChildren: () => import('./basket/basket.module').then(mod => mod.BasketModule) },
  { path: 'timer', loadChildren: () => import('./timer/timer.module').then(mod => mod.TimerModule) },
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(mod => mod.ErrorPagesModule) },
];

const routes: Routes = [
  ...baseRoutes,
  {
    path: ':lang',
    children: baseRoutes,
    resolve: {
      lang: LangAliasResolver,
    },
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 95],
      initialNavigation: 'enabledNonBlocking',
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
