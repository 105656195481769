import { LIST_CATEGORIES, LIST_GENDER_NAMES } from './constants';

export const REGEX_AGE = '^([0-9]?[0-9])$';

export const REGEX_ARRIVAL_DATETIME = '^([0-3][0-9]\\/[0-1][0-9]) ([0-9]{2}:[0-5][0-9]:[0-5][0-9])$';

export const REGEX_BIB = '^(?:n°)? ?([0-9]*)$';

export const REGEX_CATEGORY =
  '^(' +
  LIST_CATEGORIES +
  '|[vm][1-6])[ \\-]?([hmfvwg]|s|se|d|da|[va][1-6]|' +
  LIST_GENDER_NAMES +
  ')? ?([(\\/]?([0-9]*)?[.+)]?)?$';

export const REGEX_CATEGORY_POSITION =
  '^(?:\\(?([0-9]*|ABD)?[ .°e]?\\)?)? ?\\/?-?\\.? ?(' +
  LIST_CATEGORIES +
  '|[vm][1-6])[ \\-]?([hmfvwg]|s|se|d|da|[va][1-6]|' +
  LIST_GENDER_NAMES +
  '|[01]) ?(?:[(\\/]?([0-9]*)?[.+)]?)?[ \\-]?(\\(?[0-9]*[\\.°e]?\\)?)?$';

export const REGEX_CATEGORY_YEAR = '^(' + LIST_CATEGORIES + '|[vm][1-6])([hmfvw])(?:\\/([0-9]{2}))$';

export const REGEX_COUNTRY_CODE = '^([A-Z][A-Z][A-Z]?)( ?\\(.*\\))?$';

export const REGEX_DATE_DMY =
  '^((?:[012]?[0-9])|30|31)[/-](1|01|2|02|3|03|4|04|5|05|6|06|7|07|8|08|9|09|10|11|12)[/-]([1-2][90][0-9][0-9])$';

export const REGEX_DATE_YMD =
  '^([1-2][90][0-9][0-9])[/-](1|01|2|02|3|03|4|04|5|05|6|06|7|07|8|08|9|09|10|11|12)[/-]((?:[012]?[0-9])|30|31)$';

export const REGEX_EMAIL_VALIDATION =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const REGEX_GENDER = '^\\(?([hmfvwg]|' + LIST_GENDER_NAMES + ')[\\.]?\\)?$';

export const REGEX_GENDER_POSITION =
  '^(\\(?([0-9]*|ABD)?[ .°e]?\\)? ?\\/?\\-?\\.? ?([hmfvwg]|' + LIST_GENDER_NAMES + ') ?(\\(?[0-9]*[\\.°e]?\\)?)?)$';

export const REGEX_GENERAL_CATEGORY_GENDER_POSITION =
  '^(?:\\(?[0-9]*?[ .°e]?\\)?)? ?\\/?(?:' +
  LIST_CATEGORIES +
  '|[vm][1-6])?[ \\-]?([hmfvwg]|s|se|d|da|[va][1-6]|' +
  LIST_GENDER_NAMES +
  '|[01]) ?\\-?(?:[(\\/]?([0-9]*)?[.+)]?)?$';

export const REGEX_LASTNAME_FIRSTNAME =
  "((?:v.? ?d.?|van|von|de|du|d|vande|vanden|vander|y|der|del|den|des|le|la|l|ait|saint|olden|op|ter|ten|t|o|mc|mac|m)? ?(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBNáàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_' -]*)(?<![Mm]arie|[Cc]laire|[Aa]nne|[lL]aure|[Jj]ean|[cC]harles|[Pp]ierre|[Ll]ouis|[Yy]ves|[pP]aul|[Jj]acques|[Pp]eter|[Aa]ron$)) ((?:[Mm]arie|[Cc]laire|[Aa]nne|[lL]aure|[Jj]ean|[cC]harles|[Pp]ierre|[Ll]ouis|[Yy]ves|[pP]aul|[Jj]acques|[Pp]eter|[Aa]ron|[A-Z]*?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn-]*)? ?-?(?!van[W]|von[W]|de[W]|du[W]|d[W]|vande[W]|vanden[W]|vander[W]|y[W]|der[W]|del[W]|den[W]|des[W]|le[W]|la[W]|l[W]|ait[W]|saint[W][W]|olden[W]|op[W]|ter[W]|ten[W]|t[W]|o[W]|mc[W]|mac[W]|m[W])(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]{0,2}?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_-]+))?$";

export const REGEX_LINK_VALIDATION = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/;

export const REGEX_POSITION = '^\\(?([0-9]*)\\.?°?\\)?$';

export const REGEX_RUNNER_COMBO = '^(.*) ?\\[(.*)\\] ?(.*)? ?(\\((.*)\\))?$';

export const REGEX_SPEED = '^(([0-2]?[0-9])([\\.,]?[0-9][0-9]?)?)(?: ?(km\\/?h))$';

export const REGEX_TIME =
  "^(?:(?:([01]?\\d|[0-9][0-9])[:h])?([0-5]?\\d)(?:[:'m]|min))?([0-5]?\\d)?(?:'{2}|s|sec)?(?:[.,]([0-9]*))?$";

export const REGEX_TIME_DIFF = '^[\\+à] ?' + REGEX_TIME.substring(1, REGEX_TIME.length - 1) + '$';

export const REGEX_TIME_POSITION = '^' + REGEX_TIME.substring(1, REGEX_TIME.length - 1) + ' ?\\([0-9]+\\.?\\)$';

export const REGEX_UPPERNAME =
  "^([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]+(?:(?:[\\-\\( \\'\\´][ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]*[\\)\\.]?)?)+)$";

export const REGEX_LOWERNAME =
  "^([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn]+[\\.]*?(?:(?:[ \\-'][ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn]+[\\.\\)]*?)?)+)$";

export const REGEX_UPPERNAME_LOWERLASTNAME =
  '^' +
  REGEX_UPPERNAME.substring(1, REGEX_UPPERNAME.length - 1) +
  ' ' +
  REGEX_LOWERNAME.substring(1, REGEX_LOWERNAME.length - 1) +
  '$';
//"^([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]+(?:(?:[\\-\\( '][ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]*[\\)\\.]?)?)+) ([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn\.]+(?:(?:[ \\-'][\\(]?[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn]+[\\.\\)]*?)?)+)$";

export const REGEX_LOWERLASTNAME_UPPERNAME =
  '^' +
  REGEX_LOWERNAME.substring(1, REGEX_LOWERNAME.length - 1) +
  ' ' +
  REGEX_UPPERNAME.substring(1, REGEX_UPPERNAME.length - 1) +
  '$';
//"^([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn]+(?:(?:[ \\-'][ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]?[áàâäåãðéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn]+)?)+) ([ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]+(?:(?:[\\- '][ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]*)?)+)$";

export const REGEX_YEAR = '^([1-2][90][0-9][0-9])$';
