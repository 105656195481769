import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { Store } from '@ngxs/store';
import { loadResultsForRaceId } from '@betrail-libs/trail-data-state';
import { EventService } from '@betrail-libs/trail-data-state';
import { firstValueFrom } from 'rxjs';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-claim-result-form',
  templateUrl: './claim-result-form.component.html',
  styleUrls: ['./claim-result-form.component.scss'],
})
export class ClaimResultFormComponent {
  response: string;
  step = 1;
  user$ = this.authState.getUser();

  isRunner$ = this.authState.hasRole(DrupalRole.Runner);
  constructor(
    public authState: AuthStateService,
    public thisDialogRef: MatDialogRef<ClaimResultFormComponent>,
    private eventService: EventService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  cancel() {
    this.thisDialogRef.close('Cancel');
  }

  async confirm(result) {
    const u = this.authState.getUserSnapshot();
    const ruid = u?.runner?.id;
    const response = await firstValueFrom(
      this.eventService.giveResultToAnotherRunner(result, { ruid }, { name: u.name }),
    );
    this.displayConfirmation(response);
    this.step = 2;
  }

  displayConfirmation(response) {
    if (response.body.nid || response.nid) {
      this.response = 'DONE';
      this.store.dispatch(new loadResultsForRaceId(this.data.result.race.id, true));
    } else {
      this.response = 'ERROR_RETRY';
    }
  }
}
