import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Subject, of, Observable } from 'rxjs';
import { debounceTime, distinct, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { loadResultsForRunnerId, loadResultsForRaceId } from '@betrail-libs/trail-data-state';
import { AuthStateService } from '@betrail-libs/auth-state';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrupalRole, IRunner } from '@betrail-libs/shared/interfaces/interfaces';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';

@UntilDestroy()
@Component({
  selector: 'app-remove-result-form',
  templateUrl: './remove-result-form.component.html',
  styleUrls: ['./remove-result-form.component.scss'],
})
export class RemoveResultFormComponent implements OnInit {
  runnerIsNew: boolean;
  runner: any = undefined;
  knowrunner: any = 'oui';
  closeText = 'CANCEL';
  response: any = 'WORKING';
  homonymousRunners: any[];
  user: any;
  isAdmin$ = this.authState.hasRole(DrupalRole.Administrator);

  constructor(
    public thisDialogRef: MatDialogRef<RemoveResultFormComponent>,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private store: Store,
    private authState: AuthStateService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  optionForm: UntypedFormGroup = this.fb.group({
    option: ['bib_transfer', Validators.required],
    knowrunner: ['oui', Validators.required],
    runner: [''],
    runnerName: [''],
  });

  runnerName: string;

  @ViewChild('searchElem', { read: MatAutocompleteTrigger })
  searchElem: MatAutocompleteTrigger;
  @ViewChild('searchElem')
  searchNativeElem;

  chosenOption: string;
  searchValue = new Subject<any>();
  step = 1;

  searchAutocomplete: Observable<{ runners: IRunner[]; events: IEvent[]; runner: IRunner | undefined }> =
    this.searchValue.pipe(
      debounceTime(150),
      distinct(),
      switchMap(value =>
        value ? this.eventService.searchRunners(value) : of({ runners: [], runner: undefined, events: [] }),
      ),
    );

  onSearchSelect(value) {
    if (typeof value === 'string') {
      this.runnerIsNew = true;
    } else {
      this.runnerIsNew = false;
      this.searchNativeElem.nativeElement.value = value._source.title;
      this.runnerName = value._source.title;
      this.runner = value._source;
    }
  }

  searchClose() {
    this.searchElem.closePanel();
  }

  nextStep() {
    this.step++;
  }

  displayConfirmation(response) {
    if (response.body.nid || response.nid) {
      this.response = 'DONE';
      this.closeText = 'CLOSE';
      if (this.data.type && this.data.type == 'race') {
        this.store.dispatch(new loadResultsForRaceId(this.data.id, true));
      } else if (this.data.type && this.data.type == 'runner') {
        this.store.dispatch(new loadResultsForRunnerId(this.data.id));
      }
    } else {
      this.response = 'ERROR_RETRY';
    }
  }

  confirm() {
    if (this.step == 1) {
      this.chosenOption = this.optionForm.controls.option.value;

      if (this.chosenOption == 'homonymous') {
        const h = this.eventService
          .giveResultToHomonymous(this.data.result, { name: this.user.name })
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      } else if (this.chosenOption == 'bib_transfer') {
      }
      this.step = 2;
    } else if (this.step == 2) {
      this.knowrunner = this.optionForm.controls.knowrunner.value;

      if (this.knowrunner == 'oui') {
        if (this.runner === undefined) {
        } else {
          const h = this.eventService
            .giveResultToAnotherRunner(this.data.result, this.runner, { name: this.user.name })
            .subscribe(response => {
              this.displayConfirmation(response);
            });
        }
      } else if (this.knowrunner == 'non') {
        const h = this.eventService
          .giveResultToAnonymous(this.data.result, { name: this.user.name })
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      }
      this.step = 3;
    }
  }
  cancel(event) {
    event.preventDefault();

    this.thisDialogRef.close('Cancel');
  }

  onSearchChange(value) {
    this.searchValue.next(value);
    this.runner = undefined;
  }

  setKnowRunner(event) {
    this.knowrunner = this.optionForm.controls.knowrunner.value;
    //this.searchNativeElem.nativeElement.value = this.runnerName;
  }

  ngOnInit() {
    this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
      });
  }
}
